<template>
  <div class="w-full">
    <form-edit
      :value="{ fullName }"
      :actions="false"
      @delete="$router.push('personal')"
    />
    <div class="w-full flex flex-col border bg-white rounded-sm min-h-96">
      <div class="flex flex-col md:flex-row md:space-x-2 bg-white min-h-96 p-2">
        <div class="w-full md:w-1/2 flex flex-col h-full">
          <h3 class="text-sm font-semibold">Stammdaten</h3>

          <div class="rounded bg-gray-100 mt-1 p-2 pb-9">
            <div class="relative overflow-hidden h-48 w-48">
              <a
                href="#"
                @click.prevent="personal_avatar.url = null"
                class="absolute right-2 top-2 z-40 text-red-600"
                v-if="personal_avatar.url && personal_avatar.url !== 'none'"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
              <div class="w-full h-full">
                <div
                  class="w-full h-full relative z-10 bg-gray-50 rounded bg-no-repeat bg-cover bg-center"
                  :style="
                    personal_avatar.url && personal_avatar.url.length > 4
                      ? `background-image: url(${personal_avatar.url ||
                          (personal_avatar.url === 'none' &&
                            personal_avatar.url)})`
                      : ''
                  "
                >
                  <div
                    class="absolute inset-0 border-2 border-gray-300 border-dashed m-4 rounded bg-white opacity-90"
                  >
                    <p class="text-center text-gray-400 mx-auto mt-3 px-4">
                      Dateien hierher ziehen und loslassen
                    </p>
                  </div>
                </div>
                <input
                  type="file"
                  class="absolute inset-0 border w-full opacity-0 z-20"
                  @change="e => (personal_avatar = onFileChange(e))"
                />
              </div>
            </div>

            <div class="w-full flex space-x-2 my-3">
              <div class="flex flex-col w-1/3">
                <h3>Kürzel</h3>
                <input
                  type="text"
                  class="rounded p-1 border"
                  v-model="personal.initial"
                />
              </div>

              <div class="flex flex-col w-1/3">
                <h3>Personalnummer</h3>
                <input
                  type="text"
                  class="rounded p-1 border"
                  v-model="personal.personal_number"
                />
              </div>

              <div class="flex flex-col w-1/3">
                <h3>Zivilstand</h3>
                <form-select
                  placeholder="Zivilstand wählen"
                  :options="[
                    { value: 'Ledig', label: 'Ledig' },
                    { value: 'Verheiratet', label: 'Verheiratet' },
                    { value: 'Geschieden', label: 'Geschieden' },
                    { value: 'Verwitwet', label: 'Verwitwet' },
                  ]"
                  class="rounded border"
                  v-model="personal.civil_status"
                />
              </div>
            </div>

            <div class="w-full flex space-x-2 my-3">
              <div class="flex flex-col w-1/2">
                <h3>Vorname</h3>
                <input
                  type="text"
                  class="rounded p-1 border"
                  v-model="personal.first_name"
                />
              </div>

              <div class="flex flex-col w-1/2">
                <h3>Nachname</h3>
                <input
                  type="text"
                  class="rounded p-1 border"
                  v-model="personal.last_name"
                />
              </div>
            </div>

            <div class="w-full flex space-x-2 my-3">
              <div class="flex flex-col w-1/2">
                <h3>Adresse</h3>
                <textarea
                  class="rounded border p-1"
                  rows="5"
                  v-model="personal.address"
                ></textarea>
              </div>

              <div class="flex flex-col w-1/2">
                <div class="flex space-x-2 my-3">
                  <div class="flex pl-1 md:pl-0 flex-col w-1/2 md:w-1/4">
                    <h3>Postleitzahl</h3>
                    <input
                      type="text"
                      class="rounded p-1 border"
                      v-model="personal.postcode"
                    />
                  </div>

                  <div class="flex pr-2 md:pr-0 flex-col w-1/2 md:w-3/4">
                    <h3>Ort</h3>
                    <input
                      type="text"
                      class="rounded p-1 border"
                      v-model="personal.place"
                    />
                  </div>
                </div>

                <div class="flex flex-col">
                  <h3>Geburtsdatum</h3>
                  <date-picker
                    v-model="personal.birth_date"
                    input-class="border rounded p-1 text-xs appearance-none focus:outline-none w-full"
                    placeholder="Geburstdatum"
                  />
                </div>
              </div>
            </div>

            <div class="w-full flex space-x-2 my-3">
              <div class="flex flex-col w-1/2">
                <h3>Heimatort</h3>
                <input
                  type="text"
                  class="rounded p-1 border"
                  v-model="personal.hometown"
                />
              </div>

              <div class="flex flex-col w-1/2">
                <h3>Position</h3>
                <input
                  type="text"
                  class="rounded p-1 border"
                  v-model="personal.position"
                />
              </div>
            </div>

            <div class="w-full flex space-x-2 my-3">
              <div class="flex flex-col w-1/2">
                <h3>Eintrittsdatum</h3>
                <date-picker
                  v-model="personal.start_date"
                  input-class="border rounded p-1 text-xs appearance-none focus:outline-none w-full"
                  placeholder="Eintrittsdatum"
                />
              </div>

              <div class="flex flex-col w-1/2">
                <h3>Austrittsdatum</h3>
                <date-picker
                  v-model="personal.leaving_date"
                  input-class="border rounded p-1 text-xs appearance-none focus:outline-none w-full"
                  placeholder="Austrittsdatum"
                />
              </div>
            </div>

            <div class="w-full flex space-x-2 my-3">
              <div class="flex flex-col w-1/2">
                <h3>AHV Nummer</h3>
                <input
                  type="text"
                  class="rounded p-1 border"
                  v-model="personal.si_number"
                />
              </div>

              <div class="flex flex-col w-1/2">
                <h3>Bank</h3>
                <input
                  type="text"
                  class="rounded p-1 border"
                  v-model="personal.bank_institution"
                />
              </div>
            </div>

            <div class="w-full flex space-x-2 my-3">
              <div class="flex flex-col w-1/2">
                <h3>IBAN</h3>
                <input
                  type="text"
                  class="rounded p-1 border"
                  v-model="personal.bank_account"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="w-full md:w-1/2 flex flex-col h-full">
          <div class="flex flex-col">
            <h3 class="text-sm font-semibold">Kommunikation</h3>
            <div class="rounded bg-gray-100 my-1 p-2 mb-8">
              <div class="w-full flex space-x-2 my-3">
                <div class="flex flex-col w-1/2">
                  <h3>E-Mail</h3>
                  <input
                    type="text"
                    class="rounded p-1 border"
                    v-model="personal.email"
                  />
                </div>

                <div class="flex flex-col w-1/2">
                  <h3>Email (alternativ)</h3>
                  <input
                    type="text"
                    class="rounded p-1 border"
                    v-model="personal.email_alternative"
                  />
                </div>
              </div>

              <div class="w-full flex space-x-2 my-3">
                <div class="flex flex-col w-1/2">
                  <h3>Geschäft Telefon</h3>
                  <input
                    type="text"
                    class="rounded p-1 border"
                    v-model="personal.business_phone"
                  />
                </div>

                <div class="flex flex-col w-1/2">
                  <h3>Geschäft Mobile</h3>
                  <input
                    type="text"
                    class="rounded p-1 border"
                    v-model="personal.business_mobile"
                  />
                </div>
              </div>

              <div class="w-full flex space-x-2 my-3">
                <div class="flex flex-col w-1/2">
                  <h3>Privat Telefon</h3>
                  <input
                    type="text"
                    class="rounded p-1 border"
                    v-model="personal.private_phone"
                  />
                </div>

                <div class="flex flex-col w-1/2">
                  <h3>Privat Mobile</h3>
                  <input
                    type="text"
                    class="rounded p-1 border"
                    v-model="personal.private_mobile"
                  />
                </div>
              </div>
            </div>

            <h3 class="text-sm font-semibold">Notfall Kontakt</h3>
            <div class="rounded bg-gray-100 my-1 p-2">
              <div class="w-full flex space-x-2 my-3">
                <div class="flex flex-col w-1/2">
                  <h3>Vorname</h3>
                  <input
                    type="text"
                    class="rounded p-1 border"
                    v-model="personal.emergency_first_name"
                  />
                </div>

                <div class="flex flex-col w-1/2">
                  <h3>Nachname</h3>
                  <input
                    type="text"
                    class="rounded p-1 border"
                    v-model="personal.emergency_last_name"
                  />
                </div>
              </div>

              <div class="w-full flex space-x-2 my-3">
                <div class="flex flex-col w-1/2">
                  <h3>Telefon</h3>
                  <input
                    type="text"
                    class="rounded p-1 border"
                    v-model="personal.emergency_phone"
                  />
                </div>

                <div class="flex flex-col w-1/2">
                  <h3>E-Mail</h3>
                  <input
                    type="text"
                    class="rounded p-1 border"
                    v-model="personal.emergency_email"
                  />
                </div>
              </div>

              <div class="w-full flex space-x-2 my-3">
                <div class="flex flex-col w-full">
                  <h3>Anmerkung</h3>
                  <textarea
                    v-model="personal.emergency_note"
                    class="rounded border p-1"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>

            <h3 class="text-sm font-semibold">Weitere Information</h3>
            <div class="rounded bg-gray-100 my-1 p-2">
              <div class="w-full flex space-x-2 my-3">
                <div class="flex flex-col w-1/2">
                  <h3>Schlüssel Nr.</h3>
                  <input
                    type="text"
                    class="rounded p-1 border"
                    v-model="personal.key_no"
                  />
                </div>
              </div>

              <div class="w-full flex space-x-2 my-3">
                <div class="flex flex-col w-full">
                  <h3>Bemerkung</h3>
                  <textarea
                    v-model="personal.note"
                    class="rounded border p-1"
                    rows="5"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex justify-start space-x-2 text-xs text-white px-4 py-2 border-t bg-gray-100 border-gray-400"
      >
        <a
          href="#"
          @click.prevent="save"
          class="rounded-sm bg-blue-900 px-2 py-1"
          >Speichern</a
        >

        <a
          href="#"
          @click.prevent="
            updateRecord(fullName)
            updateDialog(true)
          "
          class="rounded-sm bg-white text-blue-900 px-2 py-1"
          ><i class="fa fa-trash"></i> Löschen</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getOne, deleteOne, updateOne } from '@/services/personal'

export default {
  name: 'personal-edit',
  components: {},
  data() {
    return {
      id: this.$route.params.id,
      personal_avatar: {
        file: null,
        url: 'none',
      },
      personal: {
        initial: null,
        personal_number: null,
        civil_status: null,
        first_name: null,
        last_name: null,
        address: null,
        postcode: null,
        hometown: null,
        position: null,
        place: null,
        personal_avatar: null,
        business_phone: null,
        business_mobile: null,
        private_phone: null,
        private_mobile: null,
        birth_date: null,
        start_date: null,
        leaving_date: null,
        ahv_number: null,
        bank_institute: null,
        bank_account: null,
        email: null,
        email_alternative: null,
        emergency_first_name: null,
        emergency_last_name: null,
        emergency_phone: null,
        emergency_email: null,
        emergency_note: null,
        key_no: null,
        note: null,
      },
      breadcrumbs: {
        title: 'Personal',
        actions: {
          title: 'Personal erfassen',
          component: 'new-personal',
          maxWidth: 'w-2xl',
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      getOne(this.id).then(response => {
        if (response.status === 200) {
          this.personal = {
            ...response.data,
            user: null,
            postcode:
              response.data.postcode !== 0 ? response.data.postcode : '-',
          }

          this.personal_avatar.url = this.personal.personal_avatar || ''

          this.$emit('update-title', [
            { crumb: 'Personal', link: '/personal' },
            { crumb: 'Details', link: `/personal/${this.personal.id}` },
            {
              crumb: `${this.personal.first_name} ${this.personal.last_name}`,
              link: null,
            },
          ])
        }
      })
    },
    save() {
      this.personal.personal_avatar = this.personal_avatar.url
        ? this.personal_avatar.file
        : 'none'

      updateOne(this.id, this.personal).then(response => {
        if (response.status === 200) {
          // this.$toast.success(
          //   `${response.data.data.first_name} ${response.data.data.last_name} wurde erfolgreich angepasst`
          // )

          if (this.profile.id.toString() === this.id.toString()) {
            this.updateProfile({
              ...this.profile,
              personal: response.data.data,
              avatar: response.data.data && response.data.data.personal_avatar,
              initial: response.data.data && response.data.data.initial,
            })
          }

          this.personal_avatar = {
            file: null,
            url: null,
          }

          this.$router.push(`/personal/${this.id}`)
        } else {
          // this.$toast.success('Error while updating.')
        }
      })
    },
  },
  computed: {
    personalNumber() {
      return this.personal.personal_number
        ? `${this.personal.personal_number} - `
        : ''
    },
    fullName() {
      return `${this.personal.first_name || ''} ${this.personal.last_name ||
        ''}`
    },
  },
  watch: {
    personalNumber: function(n) {
      this.breadcrumbs = {
        ...this.breadcrumbs,
        title: [
          { crumb: 'Personal', link: '/personal' },
          { crumb: 'Details', link: `/personal/${this.id}` },
          {
            crumb: `${n} ${this.fullName}`,
            link: null,
          },
        ],
      }
      this.$emit('breadcrumbs', this.breadcrumbs)
    },
    fullName: function(n) {
      this.breadcrumbs = {
        ...this.breadcrumbs,
        title: [
          { crumb: 'Personal', link: '/personal' },
          { crumb: 'Details', link: `/personal/${this.id}` },
          {
            crumb: `${this.personalNumber} ${n}`,
            link: null,
          },
        ],
      }
      this.$emit('breadcrumbs', this.breadcrumbs)
    },
    remove: function(value) {
      if (value) {
        deleteOne(this.id).then(response => {
          if (response.status === 200) {
            // this.$toast.success(response.data.message || response.data.error)
            this.updateRemove(false)
            this.$router.push('/personal')
          }
        })
      }
    },
  },
}
</script>

<style></style>
